import { FlexProps, Flex, Text, Spinner } from '@chakra-ui/core';
import React from 'react';
import { h5 } from '../../../constants/typography';

interface PillParams extends FlexProps {
  text?: string;
  loading?: boolean;
}
export const Pill: React.FC<PillParams> = ({ text, loading, ...rest }) =>
  text ? (
    <Flex alignItems="center" p="6px 16px" borderRadius="32px" backgroundColor="primaryOpaque" mb="2px" {...rest}>
      <Text {...h5} color={rest.color || 'primary'} lineHeight="20px">
        {text}
      </Text>
      {loading && <Spinner ml="4px" color={rest.color || 'primary'} size="sm" />}
    </Flex>
  ) : null;
