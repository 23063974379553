import { Flex, Grid, Stack, Text, useDisclosure } from '@chakra-ui/core';
import { Prospect, TourMap } from '../../../_generated/types';
import { bodyMedium, h1, h4, h5, h6 } from '../../constants/typography';

import React, { useState } from 'react';
import TourSectionComponent from './hoc/withApollo';
import _ from 'lodash';
import moment from 'moment';
import { Email, Phone } from '../icons';
import IdVerificationBadge from './IdVerificationBadge';
import { parseProspectInfo } from '../../utils';
import { BlockMapInfoModal } from '../InfoModals/BlockMapInfoModal';

export interface SearchResultProps {
  prospect: Prospect;
}

const bold = { ...h4, lineHeight: '14px', fontWeight: 600 };
export const KeyValuePair = ({
  label,
  value,
  gap,
  isHeader,
  isLower,
}: {
  label: string;
  value: string | boolean;
  gap?: string;
  isHeader?: boolean;
  isLower?: boolean;
}) => {
  const generateInputArray = (text: string): (string | JSX.Element)[] => {
    const textArray = text.split('\n');
    const result: (string | JSX.Element)[] = [];
    for (let i = 0; i < textArray.length; i++) {
      result.push(textArray[i]);
      if (i !== textArray.length - 1) {
        result.push(<br key={i + 'br'} />);
      }
    }
    return result;
  };

  const parsedValue =
    typeof value === 'boolean'
      ? value
        ? 'Yes'
        : 'No'
      : _.indexOf(['undefined', 'null'], typeof value) !== -1 || _.isEmpty(value)
      ? '--'
      : value;
  return !gap ? (
    <>
      <Text as="h5" {...h5}>
        {generateInputArray(label)}:
      </Text>
      <Text
        wordBreak="break-all"
        {...{ ...(isHeader ? bold : h6), textTransform: isLower ? 'lowercase' : 'capitalize' }}
      >
        {parsedValue}
      </Text>
    </>
  ) : (
    <Flex align="flex-end">
      <Text as="h5" {...h5} mr={gap}>
        {generateInputArray(label)}:
      </Text>
      <Text
        wordBreak="break-all"
        {...{ ...(isHeader ? bold : h6), textTransform: isLower ? 'lowercase' : 'capitalize' }}
      >
        {parsedValue}
      </Text>
    </Flex>
  );
};

const SearchResult: React.FC<SearchResultProps> = ({ prospect }) => {
  const formattedNeeds = {
    'fp group': prospect.needs?.floorplanGroup,
    'move in date': prospect.needs?.moveDate ? moment(prospect.needs.moveDate).format('MM/DD/YY') : undefined,
    'lease term': prospect.needs?.leaseTerm,
    'desired floor': prospect.needs?.floor,
    vehicles: prospect.needs?.vehicles,
    occupants: prospect.needs?.occupants,
    pets: prospect.needs?.pets,
    'rent desired': prospect.needs?.rentDesired ? `$${prospect.needs?.rentDesired}` : `--`,
  };
  const { isOpen: isBlockMapInfoModalOpened, onClose, onOpen } = useDisclosure(false);
  const [tourMapToBlock, setTourMapToBlock] = useState<TourMap | null>(null);

  const onOpenBlockTourMap = (tourMap: TourMap) => {
    setTourMapToBlock(tourMap);
    onOpen();
  };

  const onCloseModal = () => {
    setTourMapToBlock(null);
    onClose();
  };

  return (
    <Stack
      data-cy="SearchResult"
      borderRadius="4px"
      boxShadow="0px 10px 36px rgba(0,0,0,0.05)"
      spacing={4}
      py={4}
      width={'100%'}
    >
      <Flex justifyContent="space-between" alignItems="center" px="40px">
        <Flex alignItems="center" flexWrap="wrap">
          <Text as="h1" {...h1} fontSize="4xl" mr="20px">
            {`${prospect.firstName} ${prospect.lastName}`}
          </Text>
          <IdVerificationBadge
            idVerification={prospect.idVerification} // From DB
            idVerificationStatus={prospect.idVerificationStatus} // From SF
            idVerificationDateTime={prospect.idVerificationDateTime} // From SF
          />
        </Flex>
        <Grid gap="4px">
          {prospect.email && (
            <Flex alignItems="center" justify="flex-end">
              <Email />
              <Text pl="15px" {...bodyMedium}>
                {prospect.email}
              </Text>
            </Flex>
          )}
          {prospect.phone && (
            <Flex alignItems="center" justify="flex-end">
              <Phone />
              <Text pl="15px" {...bodyMedium}>
                {prospect.phone}
              </Text>
            </Flex>
          )}
        </Grid>
      </Flex>
      <Stack pb="1rem" px="40px">
        <Grid
          alignItems="start"
          autoColumns="max-content"
          templateAreas={{ base: "'a b'", lg: "'a b c'" }}
          columnGap={{ base: 8, lg: 20 }}
        >
          {_.chunk(_.keys(formattedNeeds), 3).map((keys, index) => (
            <Grid key={index} columnGap="8px" autoColumns="max-content" templateAreas="'a b'" rowGap={1}>
              {keys.map((key) => (
                /* eslint-disable @typescript-eslint/ban-ts-comment */
                //@ts-ignore
                <KeyValuePair label={key} value={formattedNeeds[key]} key={key} />
              ))}
            </Grid>
          ))}
        </Grid>
      </Stack>
      <Flex direction="column">
        {prospect.tours
          ?.slice()
          ?.sort((a, b) => moment(b?.startTime).diff(moment(a?.startTime)))
          ?.filter((tour) => !!tour)
          ?.map((tour, index) => {
            return (
              <TourSectionComponent
                key={tour.tourId}
                tour={tour}
                zIndex={prospect.tours!.length - index}
                prospectData={parseProspectInfo(prospect)}
                setTourMapToBlock={onOpenBlockTourMap}
              />
            );
          })}
      </Flex>
      <BlockMapInfoModal tour={tourMapToBlock} isOpen={isBlockMapInfoModalOpened} onClose={onCloseModal} />
    </Stack>
  );
};

export default SearchResult;
