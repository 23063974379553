import React from 'react';
import { BoxProps, Flex, Text } from '@chakra-ui/core';
import { bodyLarge } from '../../constants/typography';
import { Prospect } from '../../../_generated/types';
import strings from '../../constants/strings';
import { LockIcon } from '../icons';
import colors from '../../constants/colors';

type IdVerificationCheckboxProps = Pick<Prospect, 'idVerification' | 'idVerificationStatus' | 'idVerificationDateTime'>;

const IdVerificationBadge: React.FC<IdVerificationCheckboxProps> = ({
  idVerification,
  idVerificationStatus: sfStatus,
  idVerificationDateTime: sfDateTime,
}) => {
  const sfError = sfStatus === 'Not Readable' || sfStatus === 'Denied';
  const sfValid = sfStatus === 'Valid';
  const colorStyle: Pick<BoxProps, 'color' | 'backgroundColor'> = (() => {
    switch (true) {
      // Use this isValid because idVerification.isvalid is for latest idv,
      // which may be a timeout response
      case idVerification?.isValid:
      case sfValid:
        return { color: colors.lightBlue, backgroundColor: 'rgba(84, 117, 143, 0.05)' };
      case !!idVerification?.errorDetails:
      case sfError:
        return { color: colors.alertRed, backgroundColor: 'rgba(199, 0, 0, 0.05)' };
      default:
        return { color: colors.darkGray, backgroundColor: colors.lightGray };
    }
  })();

  return (
    <Flex p="6px 16px 6px 12px" {...colorStyle} borderRadius="8px">
      <LockIcon fill={colorStyle.color} />
      <Text ml="9px" {...bodyLarge} fontWeight="400" color={colorStyle.color}>
        {strings.IdVerificationBadge.text(idVerification, sfStatus, sfDateTime)}
      </Text>
    </Flex>
  );
};

export default IdVerificationBadge;
