import { useDisclosure } from '@chakra-ui/core';
import { useEffect, useRef } from 'react';

/**
 * Hook that can used to keep a single window menu shown to the user.
 * If the user presses anywhere outside the view where this hook is used, the
 * onClose() will be called. This hook shouldn't be used as a 'singleton' view component.
 * */
export const useSingleWindowMenu = () => {
  const { isOpen, onToggle, onClose, onOpen } = useDisclosure();
  const menuRef = useRef<HTMLDivElement>(null);

  const handleMouseDown = (e: any) => {
    if (menuRef.current?.contains(e.target)) {
      return;
    } else {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleMouseDown);
    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  });

  return { isOpen, onToggle, onOpen, onClose, ref: menuRef };
};
