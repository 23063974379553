import { Auth0User } from '../../types';
import { useAuth0 } from '@auth0/auth0-react';
import useBaseMapAdmin from './useBaseMapAdmin';
import { useCallback } from 'react';
import { TourMap } from '../../_generated/types';
import { useGetTourMapLazyQuery } from '../graphql/graphql';

const useCanMapBeBlocked = () => {
  const { user }: { user?: Auth0User } = useAuth0();
  const { isBaseMapAdministrator, loadingBaseMapAdmin } = useBaseMapAdmin(user?.email || '');
  const [getTourMap] = useGetTourMapLazyQuery();

  const canMapBeBlocked = useCallback(
    (tourMap: TourMap) => {
      if (loadingBaseMapAdmin || !isBaseMapAdministrator) {
        return false;
      }

      return !tourMap.isBlocked;
    },
    [user, isBaseMapAdministrator, loadingBaseMapAdmin],
  );

  const canMapBeBlockedAsync = useCallback(
    async (tourId: string | undefined) => {
      if (loadingBaseMapAdmin || !isBaseMapAdministrator || !tourId) {
        return false;
      }
      const result = await getTourMap({ variables: { tourId } });
      const tourMap = result.data?.tourMap;
      if (!tourMap) {
        return false;
      }
      return !tourMap.isBlocked;
    },
    [isBaseMapAdministrator, loadingBaseMapAdmin, user],
  );

  return { canMapBeBlocked, canMapBeBlockedAsync, isBaseMapAdministrator };
};
export default useCanMapBeBlocked;
