import { Box, Button, Flex, PseudoBox } from '@chakra-ui/core';
import React from 'react';
import { h5 } from '../../../constants/typography';
import { Ellipses } from '../../icons';
import { TourMap } from '../../../../_generated/types';
import { useSingleWindowMenu } from '../../../hooks/useSingleWindowMenu';

interface TourSectionOptionsMenuProps {
  zIndex: number;
  menuOptions: TourSectionMenuOption[];
  tourMap: TourMap | undefined;
}

export type TourSectionMenuOption = {
  setId: () => void;
  color: string;
  text: string;
  isEnabled: boolean;
  shouldBeVisible?: boolean;
};

export const TourSectionOptionsMenu = ({ zIndex, menuOptions, tourMap }: TourSectionOptionsMenuProps) => {
  const { isOpen, onToggle, ref } = useSingleWindowMenu();
  const triangle = (
    <Box
      position="absolute"
      h="16px"
      w="16px"
      backgroundColor="white"
      top="-8px"
      right="16px"
      transform="rotate(45deg)"
    />
  );

  const menuOptionView = (option: TourSectionMenuOption, key: string): JSX.Element | null => {
    const isEnabled = option.isEnabled;
    if (!option.shouldBeVisible) {
      return null;
    }

    return (
      <PseudoBox
        key={key}
        display="flex"
        onClick={(e: any) => {
          e.stopPropagation();
          isEnabled && option.setId();
        }}
        {...h5}
        color={!isEnabled ? 'gray.400' : option.color}
        backgroundColor="white"
        boxShadow="0px 10px 36px rgba(0,0,0,0.05)"
        w="140px"
        justifyContent="flex-start"
        p="16px"
        borderRadius="4px"
        _hover={{ textDecor: !isEnabled ? 'unset' : 'underline' }}
      >
        {option.text}
      </PseudoBox>
    );
  };

  // Need to check if all the options should be hidden
  const shouldHideAllOptions = menuOptions.every((option) => !option.shouldBeVisible);
  if (shouldHideAllOptions) {
    return null;
  }

  return (
    <Button
      ref={ref}
      position="relative"
      borderRadius="0 4px 4px 0"
      borderLeft="1px solid"
      borderColor="dividerLine"
      onClick={onToggle}
      h="unset"
      backgroundColor="lightGray"
      zIndex={zIndex}
      _hover={{ backgroundColor: 'lightGrayHover' }}
    >
      <Ellipses />
      <Flex visibility={isOpen ? 'visible' : 'hidden'} position="absolute" top="70%" right="0" flexDirection="column">
        {triangle}
        {menuOptions.map((option: TourSectionMenuOption) =>
          menuOptionView(option, `${option.text}${option.isEnabled}${tourMap?.id}`),
        )}
      </Flex>
    </Button>
  );
};
