import { LeasingAgentInput, PivotalData, SaveTourMapInput, TourInput } from '../../../../_generated/types';
import React, { useCallback } from 'react';
import TourSection, { TourSectionProps } from '../TourSection/TourSection';

import _ from 'lodash';
import cloneWithoutTypename from '../../../utils/cloneWithoutTypename';
import { useAuth0 } from '@auth0/auth0-react';
import {
  useGetTileLazyQuery,
  useGetTourMapLazyQuery,
  useSaveTourMapMutation,
  useUpdateTourMapMutation,
} from '../../../graphql/graphql';

const TourSectionHoc: React.FC<
  Omit<TourSectionProps, 'data' | 'onClick' | 'keylessStatus'> & { prospectData: PivotalData }
> = ({ tour, prospectData, zIndex, setTourMapToBlock }) => {
  const tourId = tour.tourMap?.tourId ?? tour.tourId!;
  const { user } = useAuth0();
  const leasingAgent: { leasingAgent: LeasingAgentInput } | Record<string, unknown> = tour.leasingAgent?.firstName
    ? { leasingAgent: _.omit(tour.leasingAgent!, ['id', '__typename']) }
    : {};
  const [saveMap] = useSaveTourMapMutation();
  const [updateMap] = useUpdateTourMapMutation();

  const [getTourMap] = useGetTourMapLazyQuery();

  const [getTileQuery] = useGetTileLazyQuery();

  const createHandler = useCallback(async () => {
    const tourInput = {
      tourId,
      ...(_.pick(tour, [
        'communityId',
        'communityName',
        'startTime',
        'status',
        'waiverSigned',
        'leasingEmailAddress',
        'leasingPhoneNumber',
        'isModel',
        'preArrivalInstructions',
        'mapDesigner',
      ]) as Omit<TourInput, 'tourId'>),
      ...leasingAgent,
    };

    const tile = (await getTileQuery({ variables: { communityId: tour.communityId || '' } })).data?.communityTile;
    const isMapsPeople = tile?.mapsPeopleEnabled ? tile.mapsPeopleEnabled : false;

    const input: SaveTourMapInput = {
      tourId,
      prospectEmail: prospectData.email!.toLowerCase(),
      propertyId: tour.propertyId!,
      tour: tourInput,
      pivotalData: prospectData,
      metadata: {
        isMapsPeople,
      },
    };

    try {
      const latestTourMap = await getTourMap({ variables: { tourId: tour.tourId! } });
      if (latestTourMap.data) {
        if (!latestTourMap.data?.tourMap) {
          const map = await saveMap({
            variables: {
              input: cloneWithoutTypename({ ...input }),
            } as {
              input: SaveTourMapInput;
            },
          });

          return !!map.data?.saveTourMap?.metadata.isMapsPeople;
        } else if (typeof latestTourMap.data.tourMap.metadata?.isMapsPeople !== 'boolean') {
          const map = await updateMap({
            variables: {
              input: {
                tourId,
                metadata: {
                  isMapsPeople,
                },
              },
            },
          });

          return !!map.data?.updateTourMap?.metadata.isMapsPeople;
        }

        return !!latestTourMap.data.tourMap.metadata.isMapsPeople;
      }
    } catch {
      if (!tour?.tourMap) {
        const map = await saveMap({
          variables: {
            input: cloneWithoutTypename({ ...input }),
          } as {
            input: SaveTourMapInput;
          },
        });
        return !!map.data?.saveTourMap?.metadata.isMapsPeople;
      }

      return !!tour.tourMap?.metadata.isMapsPeople;
    }
  }, [saveMap, updateMap, user]);

  return (
    <TourSection
      key={tour.tourId}
      tour={tour}
      onClick={createHandler}
      zIndex={zIndex}
      disabled={!!tour.isModel && !tour?.tourMap}
      setTourMapToBlock={setTourMapToBlock}
    />
  );
};

export default TourSectionHoc;
