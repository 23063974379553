import { isArray, isPlainObject } from 'lodash';

export default function cloneWithoutTypename<T>(obj: T): Omit<T, '__typename'> {
  if (!isPlainObject(obj)) {
    return obj;
  }
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const result: any = {};
  for (const key in obj) {
    if (key === '__typename') {
      continue;
    }
    /* eslint-disable @typescript-eslint/no-explicit-any */
    const value = (obj as any)[key];
    if (isPlainObject(value)) {
      result[key] = cloneWithoutTypename(value);
    } else if (isArray(value)) {
      result[key] = value.map(cloneWithoutTypename);
    } else {
      result[key] = value;
    }
  }
  return result;
}
