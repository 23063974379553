import strings from '../../constants/strings';
import Button from '../Common/Button';
import { Text } from '@chakra-ui/core';
import { bodyLarge } from '../../constants/typography';
import InfoModal from '../Modals/InfoModal';
import React, { RefObject, useReducer } from 'react';
import { BlockMapInput, TourMap } from '../../../_generated/types';
import { BlockStatus } from '../../constants/enums';
import { useBlockMapMutation } from '../../graphql/graphql';

interface BlockMapInfoModalProps {
  tour: TourMap | null | undefined;
  isOpen: boolean;
  onClose: () => void;
  ref?: RefObject<HTMLElement>;
}

interface InitialStateProps {
  isBlocking: boolean;
  isMapBlocked: boolean;
  blockStatus: BlockStatus;
}

const initialState: InitialStateProps = {
  isBlocking: false,
  isMapBlocked: false,
  blockStatus: BlockStatus.UNSTARTED,
};

type ActionType = {
  type: 'initial_state' | 'start_blocking' | 'done_blocking_success' | 'done_blocking_failure';
};

const reducer = (state: InitialStateProps, action: ActionType) => {
  switch (action.type) {
    case 'initial_state':
      return {
        ...state,
        blockStatus: BlockStatus.UNSTARTED,
      };
    case 'start_blocking':
      return {
        ...state,
        isBlocking: true,
        blockStatus: BlockStatus.BLOCKING,
      };
    case 'done_blocking_success':
      return {
        ...state,
        isBlocking: false,
        blockStatus: BlockStatus.BLOCKED,
      };
    case 'done_blocking_failure':
      return {
        ...state,
        isBlocking: false,
        blockStatus: BlockStatus.ERROR,
      };
  }
};

export const BlockMapInfoModal = ({ ref, tour, onClose, isOpen }: BlockMapInfoModalProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [blockMap] = useBlockMapMutation();

  const resetBlockingStatus = () => {
    dispatch({ type: 'initial_state' });
  };

  const closeModal = () => {
    onClose();
    resetBlockingStatus();
  };

  const onConfirm = async () => {
    if (!tour?.tourId) {
      return;
    }
    dispatch({ type: 'start_blocking' });
    const input: BlockMapInput = {
      tourId: tour.tourId,
      shouldBlockMap: true,
    };
    tour.isBlocked = true;
    try {
      await blockMap({ variables: { input } });
      dispatch({ type: 'done_blocking_success' });
    } catch (e) {
      dispatch({ type: 'done_blocking_failure' });
    } finally {
      setTimeout(() => {
        closeModal();
      }, 1200);
    }
  };

  return (
    <InfoModal
      ref={ref}
      isOpen={isOpen}
      closeModal={closeModal}
      heading={strings.SearchPage.blockMapHeading}
      Footer={
        <>
          <Button
            buttonStyle="large"
            mr="12px"
            _hover={{ bg: 'alertRed', borderColor: 'alertRed', color: 'white' }}
            onClick={onConfirm}
            isLoading={state.isBlocking}
          >
            {strings.SearchPage.blockConfirm(state.blockStatus)}
          </Button>
          <Button buttonStyle="strong" onClick={closeModal}>
            {strings.SearchPage.deleteCancel}
          </Button>
        </>
      }
    >
      <Text {...bodyLarge}>
        Are you sure you want to block this map? By blocking
        <br />
        this map, no one will be able to load this map going
        <br />
        forward. There is no unblock option.
      </Text>
    </InfoModal>
  );
};
